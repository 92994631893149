export class LookupInitializerResponse {
  program: string;
  kegiatan: string;
  output: string;
  suboutput: string;
  komponen: string;
  subkomponen: string;
  akun: string;
  detail: string;
  subdetail: string;
  ProgramName: string;
  KegiatanName: string;
  OutputName: string;
  SubOutputName: string;
  KomponenName: string;
  SubKomponenName: string;
  AkunName: string;
  DetailName: string;
  SubDetailName: string;
}
