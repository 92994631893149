declare const moment: any;
export const DateRangeRangesOptions = {
    'Hari ini': [moment(), moment()],
    'Kemarin': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    '7 Hari Terakhir': [moment().subtract(6, 'days'), moment()],
    '30 Hari Terakhir': [moment().subtract(29, 'days'), moment()],
    'Bulan Ini': [moment().startOf('month'), moment().endOf('month')],
    'Bulan Lalu': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Tahun Ini': [moment().startOf('year'), moment().endOf('year')]
};

export const DateRangeLocaleOptions = {
    direction: 'ltr',
    format: 'DD/MM/YYYY',
    separator: ' - ',
    applyLabel: 'Terapkan',
    cancelLabel: 'Batal',
    fromLabel: 'Dari',
    toLabel: 'Sampai',
    customRangeLabel: 'Periode Lainnya',
    daysOfWeek: ['Mi', 'Se', 'Se', 'Ra', 'Ka', 'Ju','Sa'],
    monthNames: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
    firstDay: 1
};