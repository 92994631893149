import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {RoleService} from '../services/role.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate{
  constructor(private authService: AuthService, private roleService: RoleService, public router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property

    const expectedRoleViewKey = route.data.expectedRoleKey;
    // const expectedRoleName = route.data.expectedRole;
    if ( !this.authService.loggedIn() || !this.roleService.getRoleValue(expectedRoleViewKey, 'View')) {
      // this.router.navigate(['auth']);
      this.authService.logout();
      window.location.href = '/auth';
      return false;
    }
    return true;
  }
}
