export const environment = {
  production: true,
  app_name: 'Monitoring Keuangan BP3IP',
  base_api: 'api/v1/',
  base_key: 'pro-mnk',
  app_key: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJlLXdvcmtzaG9wLWp3dCIsInN1YiI6IjEiLCJpYXQiOjE1NjMzN' +
    'TYxNzUsImV4cCI6MTYyNjQyODE3NX0.B6P6TRtOcdygKtzrgC29akyzSnctDpnUTxmUkaM-h8k',
  def_TA: (new Date()).getFullYear(),
  // api_url: 'http://devbispro.ddns.net:8182/',
  // base_url: 'http://devbispro.ddns.net:8183/',
  //api_url: 'http://143.198.223.12/public/',
  api_url: 'http://apimonika.bp3ip.ac.id/public/',
  // base_url: 'http://192.168.1.10:8180/',
  // api_url: 'https://api.monika.bispro-core.web.id/',
  // base_url: 'https://monika.bispro-core.web.id/',
  // base_url: 'http://143.198.223.12:8080/',
  base_url: 'http://monika.bp3ip.ac.id/',
  app_package: 'id.go.dephub.bp3ip.monitoring-keuangan',
  app_version: '1.0.0',
  app_build_number: '1',
  tax: {
    ppn_dn: '411211',
    pph_21: '411121',
    pph_22: '411122',
    pph_23: '411124',
    pph_final: '411128',
    nihil: '815113'
  },
  base_code: {
    piece: '015.04.01',
    piece_nihil: '022.12.01'
  }
};
